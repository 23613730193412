import resolveImage from "./resolveImage";
// these resolutions need to be reflexive and recursive
// ! IMPORTANT: if something's broken here, look here
// eslint-disable-next-line import/no-cycle
import resolveIssue from "./resolveIssue";

const resolveAction = action => {
  if (action) {
    const {
      title,
      uid,
      slug,
      url,
      metaTitle,
      metaDescription,
      metaImage,
      // general
      actionAlignment,
      siteId,
      includeInNextActions,
      // action details
      heading0,
      heading7,
      videoUrl,
      aspectRatio,
      backgroundImage2,
      copy0,
      copy3,
      ctaHeading,
      backgroundImage0,
      nextActions,
      alternateAction,
      alternateActionCta,
      featuredImage,
      text1,
      text0,
      // everyaction details
      actionType,
      actionCustomCtaText,
      formid,
      activistCodes,
      activistCodesExclusive,
      // thank you details
      heading3,
      copy1,
      heading4,
      heading2,
      copy2,
      heading5,
      shareUrlOverride,
      backgroundImage1,
      // impact
      showImpactGrid,
      heading1,
      impactGrid,
      // social feed
      showSocialFeed,
      socialFeed,
      // related content
      showRelatedIssues,
      relatedIssues,
      // parent issue
      issue,
      // endorser grid
      copy4,
      endorsers,
      // cardCarousel
      cardCarousel,
      // other
      twitterTitle,
      twitterDescription,
      twitterImage,
    } = { ...action };

    const sites = {
      1: "romero",
      2: "lakota",
      3: "greenpower",
    };

    const site = sites[siteId];

    return {
      uid,
      url,
      meta: {
        twitterTitle,
        twitterDescription,
        twitterImage: resolveImage(twitterImage),
        title,
        metaDescription,
        metaImage: resolveImage(metaImage),
        metaTitle,
        slug,
        url,
      },
      includeInNextActions,
      site,
      slug,
      title,
      layout: actionAlignment,
      // action details
      backgroundImage: resolveImage(backgroundImage0),
      heading: heading0 || title,
      copy: copy0 || metaDescription,
      readMore: copy3,
      readMoreCta: ctaHeading,
      alternateAction: alternateAction?.url,
      alternateActionCta,
      supporterCta: text0,
      impactCta: text1,
      videoUrl,
      aspectRatio: aspectRatio
        ? parseInt(aspectRatio.split("_")[0], 10) /
          parseInt(aspectRatio.split("_")[1], 10)
        : 16 / 9,
      placeholder: resolveImage(backgroundImage2),
      nextActions:
        nextActions && nextActions.length > 0
          ? nextActions
              .map(nextAction => resolveAction(nextAction))
              .filter(nextAction => nextAction.includeInNextActions)
          : [],
      // everyaction details
      actionType,
      cta: actionCustomCtaText,
      mobileCta: heading7,
      activistCodes: activistCodes?.map(code => code.code),
      activistCodesExclusive: activistCodesExclusive?.map(code => code.code),
      formid,
      // thank you details
      thankYouHeading: heading3,
      thankYouCopy: copy1,
      completedHeading: heading4,
      completedCopy: copy2,
      shareCta: heading5,
      shareUrlOverride,
      thankYouBackgroundImage: resolveImage(backgroundImage1),
      // impact
      showImpactGrid,
      impact: {
        enabled: showImpactGrid,
        heading: heading1,
        copy: copy2 || null,
        impact: impactGrid?.map(card => {
          return {
            ...card,
            image: resolveImage(card.image),
            logo: resolveImage(card.logo),
          };
        }),
      },
      // social feed
      showSocialFeed,
      social: {
        enabled: showSocialFeed,
        heading: heading2,
        embedCodes: socialFeed
          ?.filter(code => code.enabled)
          .map(code => {
            return code.code;
          }),
      },
      // related content
      showRelatedContent: showRelatedIssues,
      relatedContent: {
        enabled: showRelatedIssues,
        heading: "Dig Into Related Issues",
        cards: relatedIssues
          ?.filter(card =>
            card.enabled &&
            card.__typename === "Craft_relatedIssues_action_BlockType"
              ? card.action[0]
              : true
          )
          .map(card => {
            switch (card.__typename) {
              case "Craft_relatedIssues_action_BlockType":
                // eslint-disable-next-line no-case-declarations
                const cardAction = resolveAction(card.action[0]);
                return {
                  image: cardAction.metaImage,
                  program: cardAction.site || "lakota",
                  url: `/action/${cardAction.slug}`,
                  cta: cardAction.linkText || "Take Action",
                  heading: cardAction.heading,
                  label: "action",
                };
              case "entry":
                return { ...card, targetBlank: card.targetBlank };
              default:
                return {
                  targetBlank: true,
                  image: resolveImage(card.image),
                  program: card.organization,
                  url: card.linkLink,
                  cta: card.cta,
                  heading: card.heading,
                  label: card.contentType,
                };
            }
          }),
      },
      // parent issue
      issue: resolveIssue(issue?.[0]),
      metaImage: resolveImage(
        featuredImage?.length ? featuredImage : metaImage
      ),
      metaDescription,
      // supporter grid
      supporters: {
        heading: heading1,
        copy: copy4,
        supporters: endorsers?.map(e => ({
          type: e.fullname ? "person" : "organization",
          image: resolveImage(e.logo || e.image),
          name: e.orgName || e.fullname,
          title: e.personTitle,
          bio: e.descriptor,
          link: e.orgUrl || e.personUrl,
        })),
      },
      // cardCarousel
      carousel: {
        heading: heading2 || "Why This Matters",
        cards: cardCarousel
          ?.filter(card =>
            card.enabled && card.typeHandle === "action"
              ? card?.action?.[0]
              : true
          )
          .map(card => {
            switch (card.typeHandle) {
              case "action":
                // eslint-disable-next-line no-case-declarations
                const cardAction = resolveAction(card.action[0]);
                return {
                  heading: cardAction.heading,
                  copy: card.linkText || "Take Action",
                  image: cardAction.metaImage,
                  link: `/action/${cardAction.slug}`,
                };
              default:
                return {
                  heading: card.linkText,
                  copy: card.description,
                  image: resolveImage(card.image),
                  link: card.listLink?.[0]?.url,
                };
            }
          }),
      },
    };
  }
  return null;
};

export default resolveAction;
