import queryCraftGQL from "../utils/queryCraftGQL";

const query = /* GraphQL */ `
  query contributionsQuery {
    entries(section: "contribution", site: "*", after: "2024-11-13") {
      ... on contribution_public_Entry {
        date: dateCreated
        slug
        goal
        fName
        fundraiser {
          title
          slug
        }
      }
      ... on contribution_anonymous_Entry {
        date: dateCreated
        slug
        goal
        fundraiser {
          title
          slug
        }
      }
    }
  }
`;

const getContribtions = async () => {
  const { entries } = await queryCraftGQL({ query });
  return entries.map(c => ({
    id: c.slug,
    date: c.date,
    amount: c.goal / 100,
    name: c.fName || "Anonymous",
    fundraiser: c.fundraiser[0]
      ? {
          title: c.fundraiser[0].title,
          slug: c.fundraiser[0].slug,
        }
      : null,
  }));
};

export default getContribtions;
